
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import {Actions} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ChangePassword",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const confirmPassword = "";
    const password = "";

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const changePassword = Yup.object().shape({
      password: Yup.string().required().label("Password"),
      confirmPassword: Yup.string().required().label("Confirm Password")
    });

    const authControlEmail = store.getters.authControlConfigs.email;

    onMounted(async () => {
      if (!authControlEmail) {
        redirectToRouteWithName("sign-in");
      }
    });

    //Form submit function
    const onSubmitCreatePassword = async (values) => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (values.password === values.confirmPassword) {
        store.commit("setAuthConfigPassword", values.password);

        ApiService.setHeader();
        store.dispatch(Actions.CHANGE_PASSWORD, {
          newPassword: values.password
        })
          .then((res) => {
            if (res.status === 200) {
              // TODO add check for platform type - user type
              redirectToRouteWithName("business-register/personal-info");
            }
          })
          .finally(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          })
      }
    };

    return {
      onSubmitCreatePassword,
      changePassword,
      submitButton,
      password,
      confirmPassword
    };
  }
});
